import React from "react";
import "../styles/LandingPage.css";

const LandingPage = () => {
  // Function to handle button press
  const handleBook = () => {
    // window.location.href = "tel:+9613165712";
    window.location.href = "mailto:thediginnovators@gmail.com";
  };

  return (
    <div className="landing-container">
      <div className="background-overlay">
        <div className="content">
          <img src="/images/logo.png" alt="Logo" className="logo" />

          <h3>Your Middle East Digital Marketing Partner</h3>
          <h4>Web Design | SEO | Social Media | Paid Ads</h4>

          <h1>WEBSITE</h1>
          <h2>Under Construction</h2>
          <img
            src="/images/background-image.svg"
            alt=""
            className="backgroundImage"
          />
          <h4>Innovating digital strategies to grow your business.</h4>
          <h4>Trilingual solutions (Arabic, English & French).</h4>

          <h4>
            <div className="semibold">Let’s elevate your brand!</div>
          </h4>

          <button className="consultation-btn" onClick={handleBook}>
            Book your Free Consultation
          </button>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
